// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-upload-index-tsx": () => import("./../../../src/pages/upload/index.tsx" /* webpackChunkName: "component---src-pages-upload-index-tsx" */),
  "component---src-pages-upload-uploading-documents-tsx": () => import("./../../../src/pages/upload/uploading-documents.tsx" /* webpackChunkName: "component---src-pages-upload-uploading-documents-tsx" */),
  "component---src-pages-upload-verify-phone-tsx": () => import("./../../../src/pages/upload/verify-phone.tsx" /* webpackChunkName: "component---src-pages-upload-verify-phone-tsx" */),
  "component---src-pages-verify-tsx": () => import("./../../../src/pages/verify.tsx" /* webpackChunkName: "component---src-pages-verify-tsx" */)
}

